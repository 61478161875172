html,
body {
	font-variant-numeric: lining-nums;
	background-color: $c-light;
	font-family: $font-default;
	font-weight: 400;
	color: $c-dark;
	font-size: 22px;
	line-height: (30/22);

	@include tabletMaxi {
		font-size: 20px;
		line-height: (26/20);
	}

	@include mobile {
		font-size: 16px;
		line-height: (22/16);
	}
}

main {
	width: 100%;
}

img,
video,
svg {
	display: block;
	max-width: 100%;
	height: auto;
}

svg {
	fill: currentColor;
	transition: fill $ease-200, stroke $ease-200;
}

button,
a,
[class *= "button"],
[class *= "btn"],
[class *= "link"],
[type *= "submit"] {
	cursor: pointer;
}
