.footer {
	padding: 108px 0 100px;
	background-color: $c-white;
	background-image: url('/img/footer-bg.svg');
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: 20% 0;

	@include tabletMaxi {
		padding-bottom: 50px;
	}

	@include mobile {
		padding-top: 94px;
	}
}

.footer-bottom {
	display: flex;
	justify-content: space-between;
	margin-top: 108px;

	@include tablet {
		flex-direction: column;
	}

	@include mobile {
		flex-direction: column-reverse;
	}

	&__bl {
		font-size: 14px;
		line-height: 1;
		text-transform: uppercase;

		@include tablet {
			text-align: center;
		}

		@include mobile {
			font-size: 12px;
		}

		&:last-child {
			@include tablet {
				margin-top: 20px;
			}

			@include mobile {
				margin-top: 0;
				margin-bottom: 10px;
			}
		}
	}

	&__copy {
		display: inline-flex;
		align-items: center;

		span {
			display: inline-flex;
			align-items: center;

			&:first-child {
				&::before {
					display: none;
				}
			}

			&::before {
				content: "";
				width: 4px;
				height: 4px;
				margin-right: 8px;
				margin-left: 8px;
				background-color: $c-dark;
				border-radius: 50%;
			}
		}
	}

	&__design-by {
		a {
			text-decoration: underline;

			@include mobile {
				color: $c-accent;
				text-decoration: none;
			}

			@include hover {
				text-decoration: none;
			}
		}
	}
}
