.half-blocks-section {
	position: relative;
	z-index: 5;
	display: flex;
	flex-direction: row-reverse;
	margin-top: -95px;
	margin-bottom: -95px;

	@include tabletMaxi {
		display: block;
		margin-top: 0;
		margin-bottom: 0;
	}

	&--dark-reverse {
		flex-direction: row;

		.half-blocks-large {
			left: auto;
			right: 0;

			img {
				opacity: 0.8;
			}
		}

		.half-blocks-min {
			background-color: $c-dark;

			&__tt {
				color: $c-grey;
			}
		}
	}
}

.half-blocks-large {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: -1;
	width: percentage(960/1620);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10%;
	overflow: hidden;
	background-color: $c-black;
	text-align: center;

	@include tabletMaxi {
		position: relative;
		z-index: 2;
		width: 100%;
		padding: 0;
		padding-top: 46%;
	}

	@include mobile {
		padding-top: 0;
		height: 220px;
	}

	h2,
	.h1 {
		position: relative;
		z-index: 10;
		max-width: 450px;
		margin: 0 auto;
		color: $c-white;

		@include tabletMaxi {
			padding: 20px;
			position: absolute;
			top: 0;
			left: 0;
			max-width: 100%;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@include mobile {
			font-size: 28px;
			line-height: (35/28);
		}
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 0;
		opacity: 0.6;
	}
}

.half-blocks-min {
	margin-top: 95px;
	margin-bottom: 95px;
	width: percentage(800/1620);
	padding: 105px 60px 150px;
	background-color: $c-accent;
	background-image: url('/img/half-min-1-bg.svg');
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 100% auto;

	@include tabletMaxi {
		margin: 0;
		padding: 105px 0 98px;
		width: 100%;
		background-image: url('/img/half-min-1-bg-tablet.svg');
	}

	@include mobile {
		padding-top: 86px;
		padding-bottom: 90px;
		background-image: url('/img/half-min-1-bg-mob.svg');
	}

	&__inner {
		width: 380px;
		margin: 0 auto;
		text-align: center;
		color: $c-white;

		@include tabletMaxi {
			width: auto;
			max-width: 650px;
		}

		@include mobile {
			padding: 0 $wrap-sm;
		}
	}

	&__tt {
		margin-bottom: 30px;
		text-transform: uppercase;
		font-weight: 400;
		font-size: 16px;
		line-height: (20/16);

		@include tabletMaxi {
			margin-bottom: 45px;
		}

		@include mobile {
			margin-bottom: 20px;
		}
	}

	&__img {
		position: relative;
		width: 100%;
		margin-bottom: 36px;

		@include miniLaptop {
			width: 70%;
			margin-left: auto;
			margin-right: auto;
		}

		@include tabletMaxi {
			width: 454px;
			height: 454px;
			margin-bottom: 55px;
		}

		@include mobile {
			width: 230px;
			height: 230px;
			margin-bottom: 30px;
		}

		img {
			@include tabletMaxi {
				@include absolute-full-width;
				object-fit: cover;
			}
		}

		.circle-btn {
			position: absolute;
			top: 60px;
			right: -60px;

			@include tabletMaxi {
				top: 50%;
				margin-top: -60px;
			}

			@include mobile {
				right: -36px;
				margin-top: -40px;
			}
		}
	}

	.txt-1 {
		@include tablet {
			font-size: 22px;
			line-height: (28/22);
		}

		@include mobile {
			font-size: 16px;
			line-height: (22/16);
		}
	}
}
