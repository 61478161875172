.subscribe {
	text-align: center;

	&__upper-title {
		margin-bottom: 20px;
		color: $c-accent;

		@include mobile {
			margin-bottom: 10px;
		}
	}

	&__title {
		color: $c-grey;
		line-height: (52/50);
		margin-bottom: 10px;

		@include tabletMaxi {
			font-size: 40px;
			line-height: (45/40);
		}

		@include mobile {
			font-size: 28px;
			line-height: (35/28);
		}
	}

	&__input {
		max-width: 460px;
		margin: 0 auto 25px;
	}

	&__error {
		display: none;
		margin-top: 10px;
		margin-bottom: 20px;
		font-size: 17px;
		line-height: (34/17);
		text-transform: uppercase;
		color: $c-danger;
	}

	&__success {
		display: none;
	}

	&--error {
		.subscribe__error {
			display: block;
		}
	}

	&--success {
		.subscribe__form {
			display: none;
		}

		.subscribe__success {
			display: block;
		}
	}
}
