.header {
	position: fixed;
	z-index: map-get($z-index, header);
	left: 0;
	top: 0;
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: height $ease-300, background-color $ease-300;
	border-bottom: 1px solid $c-white;
	color: $c-white;

	@include mobile {
		height: 70px;
	}

	&__logo {
		svg {
			@include mobile {
				width: 112px;
				height: 26px;
			}
		}
	}

	&__left,
	&__right {
		display: flex;
		align-items: center;
		padding: 0 34px;
		height: 100%;
		border-right: 1px solid $c-white;

		@include mobile {
			padding: 0 28px;
		}
	}

	&__right {
		padding: 0 48px;
		border-right: none;
		border-left: 1px solid $c-white;

		@include tabletMaxi {
			padding-left: 40px;
			padding-right: 40px;
		}

		@include mobile {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	&__insta,
	&__phone {
		display: inline-flex;
		align-items: center;
		font-weight: 300;
		font-size: 18px;

		@include hover {
			color: $c-accent;
		}

		svg {
			flex-shrink: 0;
			width: 16px;
			height: 16px;
			margin-right: 8px;

			@include tabletMaxi {
				margin-right: 0;
			}
		}
	}

	&__phone {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: -16px;
			top: -16px;
			right: -16px;
			bottom: -16px;
		}
	}

	&__insta,
	&__phone span {
		@include tabletMaxi {
			display: none;
		}
	}

	&--scrolled:not(.header--main-nav-opened) {
		border-color: rgba($c-dark, 0.1);
		background-color: $c-white;
		color: $c-dark;

		.header__left,
		.header__right {
			border-color: rgba($c-dark, 0.1);
		}

		.header__insta,
		.header__phone {
			svg {
				fill: $c-accent;
			}
		}
	}

	&--menu-opened {
		background-color: transparent;
		color: $c-white;

		.header__left {
			padding-left: 38px;
			padding-right: 38px;

			@include mobile {
				padding-left: 32px;
				padding-right: 32px;
			}
		}
	}
}

.burger-menu-wrapp {
  position: relative;
  display: none;

	@include tabletMaxi {
		display: block;
	}

  &::before {
    content: '';
    position: absolute;
    left: -10px;
    top: -14px;
    right: -10px;
    bottom: -14px;
    z-index: -1;
	}
}

.burger-menu,
.burger-menu-box {
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
    background-color: currentColor;
    transition-property: width, transform;
    transition-duration: .35s;
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	}
}

.burger-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 30px;
  height: 16px;
  user-select: none;
  cursor: pointer;

	@include mobile {
		width: 26px;
		height: 14px;
	}

  &::before {
    top: 0;
    left: auto;
    right: 0;
	}

  &::after {
    bottom: 0;
    left: 0;
	}
}

.burger-menu-box {
  display: block;
  width: 100%;
  height: 2px;
  position: relative;
}

.header--main-nav-opened {
  .burger-menu {
		width: 22px;
		height: 12px;

    &::before,
    &::after {
      width: 0;
		}
	}

  .burger-menu-box {
    &:before {
      transform: rotate(135deg);
		}

    &:after {
      transform: rotate(45deg);
		}
	}
}
