// breakpoints (desktop first)
$b-mobile-mini: 479px;
$b-mobile-midi: 639px;
$b-mobile: 767px;
$b-tablet: 894px;
$b-tablet-maxi: 1054px;
$b-labtop-mini: 1299px;
$b-laptop: 1439px;
$b-desktop: 1899px;
$b-desktop-maxi: 2239px;

//fonts
$font-default: 'Altform', tahoma, arial, sans-serif;
$font-accent: 'Altform', tahoma, arial, sans-serif;

//colors
$c-black: #000000;
$c-white: #FFFFFF;
$c-dark: #3D4D56;
$c-light: #DAD8D6;
$c-grey: #A5A5A9;
$c-accent: #6C7C79;
$c-danger: #FF0000;

// timing
$ease-200: 200ms ease-out;
$ease-300: 300ms ease-out;
$ease-400: 400ms ease-out;
$ease-500: 500ms ease-out;
$ease-600: 600ms ease-out;

// layout
$container: 1420px;
$wrap-lg: 100px;
$wrap-md: 50px;
$wrap-sm: 20px;

// tags
$h: "h1, h2, h3, h4, h5, h6";
$input-txt: 'input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="time"], input[type="week"], textarea';

// z-index
$z-index: (
	cursor    : 100000000,
	modal     : 1200,
	overlay   : 1100,
	header    : 1000,
	footer    : 900,
	dropdown  : 800,
	menu      : 700,
	menuPage  : 500,
	cursorCar : 300
);
