#{$h},
.h1,
.h2,
.h3,
.h4,
.h5 {
	font-family: $font-accent;
	text-transform: uppercase;
}

.h1,
h1 {
	font-size: 70px;
	line-height: (80/70);

	@include tabletMaxi {
		font-size: 50px;
		line-height: (60/50);
	}

	@include mobile {
		font-size: 40px;
		line-height: (45/40);
	}
}

.h2,
h2 {
	font-size: 50px;
	line-height: (70/50);

	@include mobile {
		font-size: 28px;
		line-height: (35/28);
	}
}

.h3,
h3 {
	font-size: 24px;
	line-height: (36/24);
}

.h4,
h4 {
	font-size: 20px;
	line-height: (30/20);
}

.h5,
h5 {
	font-size: 16px;
	line-height: (20/16);
}

.h-line {
	position: relative;
	padding-bottom: 12px;
	text-align: center;

	@include mobile {
		padding-bottom: 10px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		height: 1px;
		width: 20px;
		margin-left: -10px;
		background-color: $c-accent;
	}
}

a {
	color: currentColor;
	transition: color $ease-200;

	@include hover {
		color: $c-accent;
	}
}

.text-accent {
	color: $c-accent;
}

b,
strong {
	font-weight: 700;
}

.txt {
	font-weight: 300;
	margin-bottom: 100px;

	@include mobile {
		margin-bottom: 60px;
	}

	h1 {
		font-size: 44px;
		line-height: (54/44);

		@include tabletMaxi {
			font-size: 36px;
			line-height: (46/36);
		}

		@include mobile {
			font-size: 30px;
			line-height: (34/30);
		}
	}

	h2 {
		font-size: 36px;
		line-height: (46/36);

		@include mobile {
			font-size: 24px;
			line-height: (30/24);
		}
	}

	h3 {
		@include mobile {
			font-size: 16px;
			line-height: (22/16);
		}
	}

	#{$h} {
		font-weight: 700;
		text-transform: none;
		margin-bottom: 14px;
		margin-top: 40px;

		@include mobile {
			margin-bottom: 10px;
			margin-top: 30px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	p,
	ul,
	ol,
	li {
		&:not(:last-child) {
			margin-bottom: 12px;

			@include mobile {
				margin-bottom: 10px;
			}
		}
	}

	ul {
		padding-left: 20px;
		list-style-type: disc;

		@include mobile {
			padding-left: 16px;
		}

		li {
			padding-left: 6px;

			@include mobile {
				padding-left: 2px;
			}
		}
	}

	ol {
		counter-reset: numbList;

		li {
			position: relative;
			padding-left: 32px;

			@include tabletMaxi {
				padding-left: 28px;
			}

			@include mobile {
				padding-left: 20px;
			}

			&::before {
				counter-increment: numbList;
				content: counter(numbList) '.';
				font-family: var(--font-default-1), sans-serif;
				color: var(--primary);
				font-weight: 700;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	b,
	strong {
		font-weight: 700;
	}

	i,
	em {
		font-style: italic;
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.txt-1 {
	font-size: 18px;
	line-height: (27.57/18);

	@include tabletMaxi {
		line-height: (24/18);
	}

	@include mobile {
		font-size: 16px;
		line-height: (22/16);
	}
}

.txt-2 {
	font-size: 22px;
	line-height: (30/22);

	@include mobile {
		font-size: 16px;
		line-height: (22/16);
	}
}
