[data-page-name="textPage"] {
	background-color: $c-white;
	padding-top: 190px;

	@include mobile {
		padding-top: 110px;
	}

	.header:not(.header--main-nav-opened) {
		border-color: rgba($c-dark, 0.1);
		background-color: $c-white;
		color: $c-dark;

		.header__left,
		.header__right {
			border-color: rgba($c-dark, 0.1);
		}

		.header__insta,
		.header__phone {
			svg {
				fill: $c-accent;
			}
		}
	}

	.main-nav__inner {
		@include media($b-tablet-maxi + 1, null) {
			background-color: $c-white;
			border-top: 1px solid $c-light;
		}
	}
}

.max-w-text {
	max-width: 940px;
	margin-left: auto;
	margin-right: auto;

	@include tabletMaxi {
		max-width: 640px;
	}
}
