.social-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	@include mobile {
		display: block;
	}

	&__photos {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		&:last-child {
			@include mobile {
				display: none;
			}
		}
	}

	&__photos-item {
		padding-top: 100%;
		position: relative;

		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		background-color: $c-dark;
		background-image: url('/img/instagram-bg-ptrn.svg');
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: 100% 100%;

		@include mobile {
			padding: 106px 0;
		}

		.h2,
		h2 {
			font-size: 52px;
			line-height: 1;
			margin-bottom: 14px;
			color: $c-accent;

			@include tabletMaxi {
				font-size: 35px;
				line-height: (45/35);
				margin-bottom: 10px;
			}

			@include mobile {
				margin-bottom: 14px;
			}
		}
	}

	&__tag {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 24px;
		color: $c-white;
		letter-spacing: 0.14em;
		font-weight: 400;
		font-size: 16px;
		line-height: (20/16);

		@include tabletMaxi {
			margin-bottom: 16px;
			font-size: 12px;
			line-height: (14/12);
		}

		@include mobile {
			margin-bottom: 34px;
		}
	}
}

.social-btns {
	display: flex;
	justify-content: center;

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $c-white;
		border-radius: 50%;
		width: 100px;
		height: 100px;
		border: 1px solid $c-accent;

		@include tabletMaxi {
			width: 70px;
			height: 70px;
		}

		@include mobile {
			width: 55px;
			height: 55px;
		}

		&:last-child {
			margin-left: -1px;
		}
	}

	svg {
		width: 18px;
		height: 18px;

		@include tabletMaxi {
			width: 14px;
			height: 14px;
		}
	}
}
