// Input styling
#{$input-txt} {
	// styles
	@include placeholder-color(rgba($c-dark, 0.4));
	font-weight: 300;
	font-size: 22px;
	height: 84px;
	width: 100%;
	text-align: center;
	border-bottom: 1px dashed $c-dark;

	@include mobile {
		font-size: 18px;
		height: 76px;
	}

	&[disabled] {
		// styles
		opacity: 1; // fix for safari
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	box-shadow: 0 0 0px 1000px $c-light inset;
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
	overflow: auto;
	resize: none;
}

// Checkbox and Radio styling

// .checkbox,
// .radio {
// 	position: relative;

// 	input {
// 		position: absolute;
// 		width: 0;
// 		height: 0;
// 		opacity: 0;
// 	}

// 	label {
// 		cursor: pointer;

// 		&::before {
// 			content: "";
// 			// styles for Box
// 		}

// 		&::after {
// 			content: "";
// 			// styles for Check in unchecked state
// 		}
// 	}

// 	input:checked + label {

// 		&::after {
// 			// styles for Check in checked state
// 		}
// 	}
// }
