.btn-link {
	display: inline-flex;
	align-items: center;
	position: relative;
	height: 90px;
	padding-right: 38px;
	font-size: 16px;
	line-height: (19/16);
	text-transform: uppercase;
	text-decoration: none;
	color: $c-dark;

	@include mobile {
		font-size: 14px;
		height: 80px;
		padding-right: 33px;
	}

	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 90px;
		height: 100%;
		border-radius: 50%;
		transition: transform $ease-200;
		background-image: url('/img/btn-round-bg.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		transform: rotate(45deg);

		@include mobile {
			width: 80px;
		}
	}

	svg {
		margin-left: 13px;
		fill: none;
		stroke: currentColor;
		width: 17px;
		height: 14px;
		stroke-width: 1.5px;
		transition: transform $ease-200;

		@include mobile {
			width: 14px;
			height: 12px;
		}
	}

	@include hover {
		color: currentColor;

		&::after {
			transform: rotate(45deg) scale(1.44);
		}

		svg {
			transform: translate(4px);
		}
	}

	&--light {
		color: $c-white;
	}
}

.book-btn {
	position: absolute;
	bottom: 80px;
	right: 80px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	text-transform: uppercase;
	width: 210px;
	height: 210px;
	color: $c-white;
	z-index: 3;

	@include tabletMaxi {
		right: 40px;
		bottom: 35px;
	}

	@include mobile {
		width: 108px;
		height: 108px;
		font-size: 14px;
		right: 20px;
		bottom: 20px;
	}

	@include hover {
		&::before {
			transform: scale(0.78);
		}
	}

	&::before {
		@include absolute-pseudo-full;
		border: 1px solid $c-white;
		background-color: $c-accent;
		z-index: -1;
		border-radius: 50%;
		transition: transform $ease-200;
	}

	svg {
		margin-left: 6px;
		fill: none;
		stroke-width: 1.5px;
		stroke: currentColor;

		@include mobile {
			margin-left: 2px;
			width: 12px;
			stroke-width: 2px;
		}
	}
}

.circle-btn {
	position: relative;
	z-index: 5;
	width: 120px;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: $c-white;
	font-size: 16px;
	line-height: (24/16);

	@include mobile {
		width: 80px;
		height: 80px;
		font-size: 14px;
	}

	&::before {
		content: '';
		@include absolute-full-width;
		background-color: $c-dark;
		z-index: -1;
		transition: transform $ease-200;
		border-radius: 50%;
	}

	@include hover {
		color: $c-white;

		&::before {
			transform: scale(1.28);
		}
	}
}

.cursor {
	position: fixed;
	// position: absolute;
	z-index: map-get($z-index, cursorCar);
	width: 140px;
	height: 140px;
	margin-top: -70px;
	margin-left: -70px;
	text-transform: uppercase;
	background-color: $c-dark;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	background: rgba(196, 196, 196, 0.2);
	border: 1px dashed #f0e6d7;
	backdrop-filter: blur(10px);
	border-radius: 50%;
	transition: transform $ease-200;
	transform-origin: 50% 50%;
	pointer-events: none;
	transform: scale(0);

	&--shown {
		transform: scale(1);
	}

	&--active {
		transform: scale(0.85);

		&::before,
		&::after {
			display: none;
		}
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: -30px;
		margin-top: -10px;
		width: 12px;
		height: 20px;
		background-image: url('/img/drag-arr-left.svg');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	&::after {
		left: auto;
		right: -30px;
		background-image: url('/img/drag-arr-right.svg');
	}
}
