.home-hero {
	position: relative;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	min-height: 540px;

	@include mobile {
		max-height: 700px;
	}

	&__more {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		bottom: 90px;
		text-transform: uppercase;
		color: $c-white;
		font-size: 18px;
		line-height: (21/18);
		z-index: 10;
		cursor: pointer;

		@include hover {
			opacity: 0.9;
		}

		@include tabletMaxi {
			display: none;
		}

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			margin-left: -1px;
			background-color: $c-white;
			height: 100px;
			width: 1px;
			bottom: 100%;
			margin-bottom: 25px;
		}
	}
}

.home-main-swiper {
	height: 100%;

	.swiper-slide {
		position: relative;
		height: 100%;

		img {
			@include absolute-full-width;
			object-fit: cover;
			object-position: 50% 0;
		}
	}

	.swiper-pagination {
		display: flex;
		align-items: center;
		padding: 0 40px;
		bottom: 92px;

		@include tablet {
			bottom: 50px;
		}

		@include mobile {
			bottom: 32px;
		}
	}

	.swiper-pagination-bullet {
		width: auto;
		height: auto;
		margin: 0 20px 0 0 !important;
		background-color: transparent;
		font-size: 14px;
		line-height: 1;
		letter-spacing: 0.08;
		color: $c-white;
		opacity: 0.5;

		&-active {
			opacity: 1;
		}
	}
}
