.home-intro {
	padding-top: 246px;
	padding-bottom: 188px;
	background-image: url('/img/home-intro-bg.svg');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: 50% 218px;

	@include tabletMaxi {
		padding-top: 305px;
		padding-bottom: 130px;
		background-position: 50% 100px;
	}

	@include mobile {
		padding-top: 150px;
		padding-bottom: 134px;
		background-image: url('/img/home-intro-bg-mobile.svg');
		background-position: 50% 125px;
	}

	.container {
		position: relative;

		@include tabletMaxi {
			min-height: 630px;
		}

		@include tablet {
			min-height: 400px;
		}
	}

	&__img-top {
		// right: percentage(80/1460);
		right: 80px;
		top: -170px;
		width: percentage(300/1420);
		@include aspect-ratio(375, 300);
		position: absolute;

		@include miniLaptop {
			right: 0;
		}

		@include tabletMaxi {
			width: percentage(216/924);
		}

		@include mobile {
			top: -112px;
			width: 105px;
			height: 132px;
		}
	}

	&__img-bottom {
		top: 100%;
		margin-top: -45px;
		right: percentage(160/1420);
		width: percentage(300/1420);
		@include aspect-ratio();
		position: absolute;
		z-index: 2;

		@include miniLaptop {
			margin-top: 20px;
		}

		@include tabletMaxi {
			right: percentage(80/940);
			width: percentage(216/940);
			left: auto;
			right: 80px;
		}

		@include tablet {
			margin-top: 0;
		}

		@include mobile {
			margin-top: 42px;
			width: 164px;
			height: 164px;
			right: 0;
		}
	}

	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

.home-intro-grid {
	display: flex;

	@include tabletMaxi {
		align-items: center;
		margin-top: -95px;
	}

	@include tablet {
		margin-top: -40px;
		align-items: flex-start;
	}

	@include mobile {
		margin-top: 20px;
	}

	@include media(null, $b-mobile-mini) {
		display: block;
	}

	&__img {
		flex-shrink: 0;
		margin-right: percentage(260/1420);
		width: percentage(620/1420);
		@include aspect-ratio(750, 620);

		@include miniLaptop {
			margin-right: percentage(140/1085);
		}

		@include tabletMaxi {
			margin: 0;
			width: percentage(452/924);
			@include aspect-ratio(535, 452);
		}

		@include tablet {
			width: percentage(400/768);
		}

		@include mobile {
			width: percentage(200/480);
		}

		@include media(null, $b-mobile-mini) {
			width: 223px;
			height: 264px;
		}
	}
}

.home-intro-grid-col {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 100px 0;

	@include tabletMaxi {
		padding: 140px 0 60px;
	}

	@include tablet {
		padding: 80px 0;
	}

	@include mobile {
		padding: 50px 0;
	}

	@include media(null, $b-mobile-mini) {
		padding: 0;
	}

	.btn-link {
		margin-right: auto;

		@include tabletMaxi {
			margin-left: 100px;
		}

		@include tablet {
			margin-left: 60px;
		}

		@include mobile {
			margin-left: 40px;
		}

		@include media(null, $b-mobile-mini) {
			margin: 0 auto 60px 0;
		}
	}

	.txt-1,
	.txt-2 {
		max-width: 460px;
		margin-bottom: 60px;

		@include tabletMaxi {
			margin-left: 100px;
			margin-bottom: 0;
		}

		@include tablet {
			margin-left: 60px;
		}

		@include mobile {
			margin-left: 40px;
		}

		@include media(null, $b-mobile-mini) {
			margin: 32px 0 0;
		}
	}
}

.home-intro-title {
	position: relative;
	z-index: 5;
	margin-bottom: 210px;

	@include miniLaptop {
		margin-bottom: 140px;
	}

	@include tabletMaxi {
		max-width: 750px;
		margin-bottom: 0;
		font-size: 50px;
		line-height: (60/50);
	}

	@include tablet {
		font-size: 46px;
		line-height: (54/46);
	}

	@include mobile {
		max-width: 460px;
		margin-left: auto;
		margin-right: auto;
		font-size: 28px;
		line-height: (30/28);
	}

	@include media(null, $b-mobile-mini) {
		margin-left: 0;
	}

	&__line {
		display: block;
		padding-left: 58px;

		@include tabletMaxi {
			text-align: right;
			padding-left: 0;
		}

		@include media(null, $b-mobile-mini) {
			display: inline;
		}

		span {
			@include miniLaptop {
				margin-left: -58px;
				display: block;
				text-align: center;
			}

			@include tabletMaxi {
				margin-left: 0;
			}

			@include media(null, $b-mobile-mini) {
				text-align: left;
				display: inline;
			}
		}
	}
}
