.main-nav {
	position: fixed;
	z-index: map-get($z-index, menu);
	left: 0;
	bottom: 0;
	width: 100%;
	height: 52px;
	background-color: $c-dark;
	transition: opacity $ease-300;

	@include tabletMaxi {
		display: block;
		opacity: 0;
		pointer-events: none;
		bottom: auto;
		top: 0;
		height: 100vh;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&::before {
		@include absolute-pseudo-full;
		background-color: rgba($c-black, 0.4);
		z-index: 2;
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: 0 $wrap-md;
		background-color: $c-light;
		position: relative;
		z-index: 10;

		@include tabletMaxi {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			overflow: auto;
			background-color: transparent;
			padding: 230px 60px 230px 100px;
		}

		@include mobile {
			padding: 180px 18px 160px 32px;
		}
	}

	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		text-transform: uppercase;

		@include tabletMaxi {
			flex-direction: column;
			align-items: flex-start;
			color: $c-white;
			font-weight: 300;
			font-size: 70px;
			line-height: (85/70);
			counter-reset: numbList;
		}

		@include mobile {
			font-size: 28px;
			line-height: (35/28);
		}

		li {
			position: relative;
			align-items: center;
			display: inline-flex;

			@include tabletMaxi {
				margin-top: 30px;
				align-items: flex-end;
			}

			@include mobile {
				margin-top: 20px;
			}

			&:first-child {
				@include tabletMaxi {
					margin-top: 0;
				}

				&::before {
					display: none;

					@include tabletMaxi {
						display: block;
					}
				}
			}

			&::before {
				content: "";
				width: 4px;
				height: 4px;
				margin-right: 26px;
				margin-left: 26px;
				background-color: $c-dark;
				border-radius: 50%;

				@include tabletMaxi {
					width: auto;
					height: auto;
					margin-right: 60px;
					font-weight: 400;
					font-size: 22px;
					line-height: (34/22);
					margin-left: 0;
					margin-bottom: 8px;
					border-radius: 0;
					background-color: transparent;
					counter-increment: numbList;
					content: '0' counter(numbList) '.';
				}

				@include mobile {
					margin-right: 28px;
					margin-bottom: 3px;
					font-size: 14px;
					line-height: (20/14);
				}
			}
		}
	}

	&--opened {
		opacity: 1;
		pointer-events: auto;
	}
}
