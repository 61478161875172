.experience-section {
	padding-top: 306px;
	padding-bottom: 270px;
	background-image: url('/img/experience-bg.svg');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: 50% 0;

	@include tabletMaxi {
		padding-top: 225px;
		padding-bottom: 190px;
		background-position: 50% 100px;
	}

	@include mobile {
		padding-top: 68px;
		padding-bottom: 40px;
		background: none;
		background-color: $c-white;
	}

	img {
		@include absolute-full-width;
		object-fit: cover;
	}
}

.experience-grid {
	display: flex;

	@include miniLaptop {
		align-items: flex-start;
	}

	@include tablet {
		margin-top: -40px;
		align-items: flex-start;
	}

	@include mobile {
		margin-top: 20px;
	}

	@include media(null, 540px) {
		display: block;
		position: relative;
		padding-top: 108px;
	}

	&__img {
		flex-shrink: 0;
		margin-right: percentage(180/1420);
		margin-left: percentage(80/1420);
		width: percentage(620/1420);
		@include aspect-ratio(750, 620);

		@include miniLaptop {
			margin-right: percentage(100/1085);
			margin-left: 0;
		}

		@include tabletMaxi {
			margin: 0;
			width: 48%;
		}

		@include tablet {
			width: 40%;
		}

		@include mobile {
			max-width: 203px;
			min-width: 140px;
			flex-grow: 1;
			width: auto;
		}

		@include media(null, 540px) {
			width: 282px;
			max-width: none;
			height: 347px;
			margin-bottom: 60px;
		}
	}
}

.experience-grid-col {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 100px 0;

	@include miniLaptop {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@include tabletMaxi {
		padding-left: percentage(100/924);
		width: 52%;
	}

	@include tablet {
		width: 60%;
		padding-top: 30px;
		padding-bottom: 0;
	}

	@include mobile {
		padding-left: 30px;
	}

	@include media(null, 540px) {
		padding: 0;
		width: 100%;
	}

	h2,
	.h1 {
		margin-bottom: 32px;

		@include tabletMaxi {
			margin-left: 40px;
		}

		@include tablet {
			font-size: 40px;
		}

		@include media(null, 540px) {
			position: absolute;
			top: 0;
			left: 0;
			margin-left: 20px;
		}

		@include mobile {
			font-size: 28px;
			line-height: (35/28);
		}

		span {
			display: block;
			margin-left: -40px;

			@include media(null, 540px) {
				margin-left: -20px;
			}
		}
	}

	.btn-link {
		margin-right: auto;
	}

	.txt-1,
	.txt-2 {
		max-width: 460px;
		margin-bottom: 52px;

		@include tabletMaxi {
			margin-bottom: 32px;
		}
	}
}
