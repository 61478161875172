.menu-hero {
	position: relative;
	background-color: $c-dark;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	min-height: 600px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $c-white;

	@include mobile {
		min-height: 272px;
	}

	&::before {
		content: "";
		@include absolute-pseudo-full;
		background-color: rgba($c-black, 0.3);
	}

	h1, .h1 {
		position: relative;
		z-index: 3;

		@include media($b-mobile + 1) {
			font-size: 60px;
		}
	}
}
