.location {
	text-align: center;

	@include mobile {
		background-color: $c-white;
	}

	&__head {
		padding-top: 140px;
		padding-bottom: 120px;
		background-color: $c-white;

		@include mobile {
			padding-top: 56px;
			padding-bottom: 60px;
		}
	}

	&__bottom {
		padding-bottom: 110px;

		@include tabletMaxi {
			padding-bottom: 74px;
		}
	}

	.h5 {
		margin-bottom: 26px;
	}

	h2,
	.h2,
	h1,
	.h1 {
		margin-bottom: 26px;
		// color: rgba($c-dark, 0.25);
		color:#A5A5A9;

		@include tabletMaxi {
			font-size: 60px;
			line-height: (72/60);
		}

		@include mobile {
			font-size: 28px;
		}
	}

	.txt,
	.txt-2 {
		margin-left: auto;
		margin-right: auto;
		max-width: 620px;
	}
}
