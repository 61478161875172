.team-main {
	padding: 312px 0 328px;
	background-color: $c-dark;
	color: $c-white;

	@include miniLaptop {
		padding: 252px 0 268px;
	}

	@include tabletMaxi {
		background-image: url('/img/team-bg-tablet.svg');
		background-repeat: no-repeat;
		background-position: 50% 0%;
		background-size: 100% auto;
		padding: 176px $wrap-md 137px;
	}

	@include mobile {
		padding: 110px $wrap-sm 70px;
		background-position: 50% calc(100% + 30px);
	}
}

.cards-carousel {
	position: relative;
	z-index: 50;

	@include media($min: 1620px) {
		padding-left: calc((100% - 1420px) / 2);
	}

	@include media($max: 1619px) {
		padding-left: $wrap-lg;
	}

	@include miniLaptop {
		padding-left: $wrap-md;
	}

	@include tabletMaxi {
		padding-left: 0;
	}

	&__container {
		display: flex;

		@include tabletMaxi {
			flex-direction: column;
		}
	}

	&__info {
		margin-right: 100px;
		margin-top: 50px;
		width: 380px;
		flex: none;

		@include laptop {
			margin-right: 75px;
			width: 300px;
		}

		@include tabletMaxi {
			margin: 0 0 85px;
			max-width: 535px;
			width: 100%;
		}

		.txt-1,
		.txt-2 {
			@include mobile {
				font-size: 16px;
				line-height: (22/16);
			}
		}

		h1 {
			margin-bottom: 50px;
			color: $c-grey;

			@include miniLaptop {
				margin-bottom: 32px;
			}

			@include mobile {
				font-size: 28px;
				line-height: 1.5em;
			}
		}
	}

	&__slider {
		flex: auto;
		padding-bottom: 78px;
		cursor: none;

		@include tabletMaxi {
			padding-bottom: 0;
			margin-left: -50px;
			margin-right: -50px;
			padding-left: 50px;
			padding-right: 50px;
		}

		@include mobile {
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__card {
		width: 380px;
		position: relative;
		@include aspect-ratio(600, 380);

		@include tabletMaxi {
			width: 370px;
		}

		&:nth-child(2n-1) {
			@include media($min: 1300px) {
				margin-bottom: 50px;
			}
		}

		&:nth-child(2n) {
			@include media($min: 1300px) {
				margin-top: 50px;
			}
		}
	}

	&__card-bg {
		@include absolute-background;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: percentage(374/600);
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 26.83%,
				rgba(0, 0, 0, 0.8) 81.67%
			);
			opacity: 0.9;
		}
	}
}

.cards-carousel-more {
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 30px 30px 30px 36px;

	@include media(1055px, 1100px) {
		padding: 16px 16px 16px 20px;
	}

	@include mobile {
		padding: 22px 22px 22px 26px;
	}

	&__name {
		margin-bottom: 18px;
		font-size: 30px;
		line-height: (35/30);
		text-transform: uppercase;

		@include mobile {
			margin-bottom: 14px;
			font-size: 25px;
			line-height: (30/25);
		}

		span {
			display: block;
			padding-left: 46px;
		}
	}

	&__pos {
		position: relative;
		padding-top: 20px;
		font-size: 18px;
		line-height: (26/18);
		color: $c-grey;

		@include mobile {
			padding-top: 14px;
			font-size: 16px;
			line-height: (22/16);
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 24px;
			height: 1px;
			background-color: $c-grey;

			@include mobile {
				width: 20px;
			}
		}
	}
}
