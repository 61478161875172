.wrapper {
	padding: 0 $wrap-lg;

	@include miniLaptop {
		padding: 0 $wrap-md;
	}

	@include mobile {
		padding: 0 $wrap-sm;
	}
}

.container {
	width: 100%;
	max-width: $container;
	margin: 0 auto;
}

.anchor-wrapp {
	position: relative;
}

.anchor-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
