.events-section {
	padding-top: 226px;
	padding-bottom: 265px;
	background-image: url('/img/events-bg.svg');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: 50% 0;
	overflow: hidden;

	@include tabletMaxi {
		padding-top: 102px;
		padding-bottom: 126px;
	}

	@include mobile {
		padding: 56px 0;
		background-image: url('/img/events-bg-mob.svg');
	}
}

.events-info {
	display: flex;
	margin-bottom: 100px;

	@include tabletMaxi {
		margin-bottom: 90px;
	}

	@include mobile {
		display: block;
		margin-bottom: 62px;
	}

	h2,
	.h1 {
		flex-shrink: 0;
		margin-right: percentage(160/1420);
		width: percentage(620/1420);

		@include miniLaptop {
			margin-right: percentage(60/1085);
		}

		@include tabletMaxi {
			margin: 0;
			width: 48%;
			padding-right: 60px;
		}

		@include tablet {
			width: auto;
		}

		@include mobile {
			padding-right: 0;
			font-size: 28px;
			line-height: (35/28);
		}

		span {
			display: block;
			white-space: nowrap;
			padding-left: 40px;

			@include mobile {
				padding-left: 20px;
			}
		}
	}

	&__col {
		@include tabletMaxi {
			padding-top: 65px;
		}

		@include mobile {
			padding-top: 24px;
		}

		.txt-1,
		.txt-2 {
			max-width: 460px;
			margin-bottom: 60px;

			@include tabletMaxi {
				margin-bottom: 36px;
				max-width: 380px;
			}

			@include mobile {
				max-width: 100%;
			}
		}
	}
}
