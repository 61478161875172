.swiper-slide {
	display: flex;
	flex-direction: column;
	height: auto;
}

.swiper-horizontal>.swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
	left: 0;
	max-width: 780px;
	width: 100%;
	height: 3px;
	bottom: 0;

	@include tabletMaxi {
		position: relative;
		max-width: 762px;
		margin: 78px auto 0;
	}

	@include mobile {
		margin-top: 50px;
	}
}

.swiper-scrollbar {
	position: relative;
	background-color: transparent;

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		margin-top: -1px;
		width: 100%;
		height: 1px;
		background-color: rgba($c-grey, 0.2);
	}
}

.swiper-scrollbar-drag {
	background-color: $c-grey;
	border-radius: 0;
}

.swiper-button-prev {
	left: 0;
}

.swiper-button-next {
	right: 0;
}

.swiper-button-next,
.swiper-button-prev {
	width: 84px;
	height: 84px;
	background-color: $c-accent;
	border-radius: 50%;
	transition: background-color $ease-200;

	@include hover {
		background-color: darken($c-accent, 10%);
	}

	&::before,
	&::after {
		display: none;
	}

	svg {
		stroke: $c-white;
		stroke-width: 1.5px;
		fill: none;
		width: 16px;
		height: 16px;
	}
}
