[data-page-name='menuPage'] {
	.main-nav {
		display: none;

		@include tabletMaxi {
			display: block;
		}
	}
}

.menu-nav-wrapp {
	width: 100%;
	height: 52px;

	@include mobile {
		height: 90px;
	}

	&--scrolled {
		.menu-nav-inner {
			position: fixed;
			z-index: map-get($z-index, menuPage);
			top: 100px;
			left: 0;
			width: 100%;

			@include mobile {
				top: 70px;
			}
		}
	}

	&--opened {
		@include mobile {
			.menu-nav-inner {
				position: fixed;
				z-index: map-get($z-index, menuPage);
				top: 70px;
				left: 0;
				overflow: auto;
				width: 100%;
				background-color: rgba(#f4f1f0, 0.7);
				height: auto;
			}

			.menu-nav {
				height: auto;

				&__mobile-title {
					img {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	&--opened {
		@include mobile {
			.menu-nav-inner {
				bottom: 0;
			}

			.menu-nav {
				&__mobile-title {
					display: flex;

					&::before {
						display: block;
					}
				}

				&__list {
					display: flex;
				}
			}
		}
	}
}

.menu-nav-inner {
	height: 52px;
	transition: top $ease-200;

	@include mobile {
		height: 90px;
	}
}

.menu-nav {
	height: 100%;
	width: 100%;
	padding: 0 $wrap-md;
	background-color: $c-white;
	display: flex;
	align-items: center;
	justify-content: center;

	@include mobile {
		display: block;
		background-color: $c-accent;
		justify-content: flex-start;
		padding: 0 $wrap-sm;
	}

	&__mobile-title {
		display: none;

		@include mobile {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 90px;
			color: $c-white;
			text-transform: uppercase;
			font-size: 16px;

			&::before {
				display: none;
				content: '';
				position: absolute;
				left: -20px;
				right: -20px;
				bottom: 0;
				background-color: $c-light;
				height: 1px;
			}
		}

		img {
			width: 10px;
			height: 10px;
		}
	}

	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		text-transform: uppercase;

		@include tablet {
			font-size: 14px;
		}

		@include mobile {
			display: none;
			// display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			background-color: $c-accent;
			margin-left: -20px;
			margin-right: -20px;
			padding: 0 20px;
			font-size: 16px;
			line-height: 44px;
		}

		li {
			position: relative;
			align-items: center;
			display: inline-flex;

			@include mobile {
				display: flex;
				width: 100%;
				border-bottom: 1px dashed $c-white;

				&:last-child {
					border: none;
				}
			}

			&:first-child {
				@include tabletMaxi {
					margin-top: 0;
				}

				&::before {
					display: none;

					@include mobile {
						display: block;
					}
				}
			}

			&::before {
				content: '';
				width: 4px;
				height: 4px;
				margin-right: 26px;
				margin-left: 26px;
				background-color: rgba($c-dark, 0.5);
				border-radius: 50%;

				@include tablet {
					margin-left: 18px;
					margin-right: 18px;
				}

				@include mobile {
					margin-left: 0;
					background-color: $c-white;
					margin-right: 20px;
				}
			}
		}

		.active {
			a {
				color: $c-dark;

				@include mobile {
					color: $c-white;
				}
			}
		}

		a {
			color: rgba($c-dark, 0.5);

			@include mobile {
				width: 100%;
				display: block;
				padding: 25px 0;
				color: $c-white;
			}

			@include hover {
				color: $c-dark;
			}
		}
	}
}
