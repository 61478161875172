.not-found {
	min-height: 100vh;
	padding: 200px 0;
	display: flex;
	text-align: center;
	background-position: 50% 0;
	background-repeat: no-repeat;
	background-size: cover;
	color: $c-white;

	@include tabletMaxi {
		min-height: 600px;
		min-height: 100px;
		padding: 190px 0 120px;
	}

	@include mobile {
		min-height: 100vh;
	}

	.wrapper {
		width: 100%;
		margin-top: auto;
		margin-bottom: auto;
	}

	&__btn {
		.btn-link {
			color: $c-white;
		}
	}

	&__404 {
		font-weight: 300;
		font-size: 150px;
		line-height: 1em;
		margin-bottom: 38px;

		@include tabletMaxi {
			font-size: 100px;
			line-height: (120/100);
		}

		@include mobile {
			font-size: 90px;
			line-height: 1em;
			margin-bottom: 10px;
		}
	}

	&__text {
		margin-bottom: 70px;
		font-size: 28px;
		line-height: (34/28);

		@include tabletMaxi {
			margin-bottom: 40px;
		}

		@include mobile {
			margin-bottom: 50px;
			font-size: 24px;
			line-height: (30/24);
		}
	}
}
