.menu-section {
	padding: 140px 0 254px;
	background-image: url('/img/menu-decor-bg.svg');
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 100% auto;

	@include tabletMaxi {
		padding-bottom: 110px;
		background-image: url('/img/menu-decor-bg-tablet.svg');
	}

	@include mobile {
		padding: 0;
	}
}

.menu-list-wrapp {
	position: relative;
	margin-bottom: 120px;
	padding: 40px 110px;
	background-color: rgba($c-white, 0.5);

	@include miniLaptop {
		padding-left: 60px;
		padding-right: 60px;
	}

	@include tabletMaxi {
		padding-left: 40px;
		padding-right: 40px;
		max-width: 836px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 100px;
	}

	@include mobile {
		padding-top: 34px;
		padding-bottom: 40px;
		padding-left: 20px;
		padding-right: 20px;
		margin: 0 -20px 80px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.anchor-link {
		top: -170px;
	}

	&__head {
		display: flex;
		align-items: center;
		margin-bottom: 40px;
		font-size: 20px;
		line-height: 1;
		color: $c-accent;

		@include mobile {
			margin-bottom: 24px;
			font-size: 16px;
			line-height: (22/16);
		}

		&::before {
			content: '';
			width: 5px;
			height: 5px;
			background-color: $c-accent;
			border-radius: 50%;
			margin-left: 2px;
			margin-right: 32px;

			@include mobile {
				width: 4px;
				height: 4px;
				margin-right: 20px;
			}
		}
	}

	&__bottom {
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		line-height: (24/16);

		@include mobile {
			font-size: 14px;
			line-height: (20/14);
		}
	}
}

.menu-list-bl {
	margin-bottom: 116px;

	@include mobile {
		margin-bottom: 60px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__head {
		margin-bottom: 30px;

		@include mobile {
			margin-bottom: 24px;
		}

		h2,
		.h2 {
			line-height: (80/50);

			@include tabletMaxi {
				font-size: 40px;
			}

			@include mobile {
				margin-bottom: 4px;
				font-size: 28px;
				line-height: (35/28);
			}
		}

		h3,
		.h5 {
			font-weight: 700;
			color: $c-accent;
		}
	}
}

.menu-list-spec-bl {
	margin: 0 -110px 156px;
	padding: 40px 110px;
	background-color: $c-dark;
	color: $c-grey;

	@include miniLaptop {
		padding-left: 60px;
		padding-right: 60px;
		margin-left: -60px;
		margin-right: -60px;
	}

	@include tabletMaxi {
		padding-left: 40px;
		padding-right: 40px;
		margin-top: -40px;
		margin-left: -40px;
		margin-right: -40px;
	}

	@include mobile {
		padding-left: 20px;
		padding-right: 20px;
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: 40px;
	}
}

.menu-list-spec {
	font-weight: 300;
	font-size: 18px;
	line-height: (22/18);

	@include mobile {
		font-size: 16px;
		line-height: (22/16);
	}

	h3,
	.h4 {
		font-weight: 400;
		color: $c-accent;

		@include mobile {
			margin-bottom: 12px;
			font-size: 16px;
			line-height: (22/16);
		}
	}

	li {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.menu-list-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(40%, 480px));
	gap: 0 60px;
	justify-content: space-between;

	@include tabletMaxi {
		gap: 0 40px;
	}

	@include mobile {
		display: block;
	}

	> li {
		padding: 30px 0 40px;
		border-bottom: 1px dashed $c-dark;

		&:last-child {
			border: none;
		}
	}

	&__title {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		h3,
		.h5 {
			font-weight: 700;
		}
	}

	&__val {
		flex-shrink: 0;
		white-space: nowrap;
		margin-left: 46px;
		font-weight: 700;
		text-transform: none;
	}
}

.menu-list {
	li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		font-size: 18px;
		line-height: (21/18);

		@include mobile {
			font-size: 16px;
			line-height: (22/16);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		font-weight: 300;

		strong,
		b {
			font-weight: 400;
		}
	}

	&__price {
		flex-shrink: 0;
		white-space: nowrap;
		color: $c-accent;
		margin-left: 46px;
	}
}
