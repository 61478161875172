.centered-cards {
	overflow: visible;

	.swiper-button-next,
	.swiper-button-prev {
		top: 230px;
		margin-top: 0;

		@include miniLaptop {
			top: 180px;
		}

		@include tabletMaxi {
			display: none;
		}
	}

	.swiper-slide {
		width: percentage(780/1420);

		@include tabletMaxi {
			width: percentage(768/930);
		}

		@include mobile {
			width: 100%;
		}
	}

	.swiper-slide-active {
		.centered-cards__info {
			opacity: 1;
			transform: scale(1);
		}
	}

	&__img {
		position: relative;
		@include aspect-ratio(780/540);

		@include tabletMaxi {
			@include aspect-ratio(768/506);
		}

		img {
			@include absolute-full-width;
			object-fit: cover;
		}
	}

	&__info {
		position: relative;
		z-index: 5;
		padding: 65px;
		text-align: center;
		background-color: $c-accent;
		color: $c-white;
		margin: -160px 60px 0;
		opacity: 0;
		transform: scale(0.8);
		transition: opacity $ease-300, transform $ease-300;

		@include miniLaptop {
			padding: 40px;
			margin-top: -140px;
		}

		@include tabletMaxi {
			margin: 30px 0 0;
			padding: 60px 45px 45px;
		}

		@include mobile {
			margin-top: 16px;
			padding: 30px 24px;
		}

		.txt-1 {
			max-width: 460px;
			margin: 25px auto;

			@include mobile {
				margin: 16px 0 20px;
				font-size: 14px;
				line-height: (21/14);
			}
		}
	}

	&__title {
		font-size: 30px;
		line-height: (35/30);
		text-transform: uppercase;
		color: rgba($c-white, 0.5);

		@include mobile {
			font-size: 18px;
			line-height: (21/18);
		}
	}

	&__date {
		display: inline-flex;
		align-items: center;
		font-size: 18px;

		@include mobile {
			font-size: 16px;
		}

		svg {
			width: 20px;
			height: 20px;
			margin-right: 12px;

			@include mobile {
				width: 18px;
				height: 18px;
				margin-right: 10px;
			}
		}
	}
}
