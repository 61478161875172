#map {
	width: 100%;
	height: 690px;
	background-color: $c-grey;

	.gm-style {
		.gm-style-iw-c {
			max-width: 260px !important;
			padding: 0;
			border-radius: 0;
			box-shadow: none;

			@include mobile {
				max-width: 230px !important;
			}

			> button {
				display: none !important;
			}
		}

		.gm-style-iw-tc {
			filter: none;
			transform: translate(-138px);
			height: 20px;
			top: -10px;

			@include mobile {
				transform: translate(-126px);
			}

			&::after {
				background-color: $c-accent;
				height: 100%;
				transform: skewX(-50deg);
			}
		}

		.gm-style-iw-d {
			overflow: auto !important;
		}
	}
}

.map-dialog {
	padding: 28px 40px;
	font-family: $font-default;
	font-size: 18px;
	line-height: (25/18);
	text-align: left;
	background-color: $c-accent;
	color: $c-white;

	@include mobile {
		padding: 20px 30px;
		font-size: 16px;
		line-height: (22/16);
	}

	&__title {
		font-family: var(--font-default), sans-serif;
		font-weight: 500;
		margin-bottom: 10px;
	}

	&__info {
		margin-bottom: 14px;
	}

	> button {
		display: none !important;
	}
}

.location-map {
	margin-bottom: 92px;

	@include tabletMaxi {
		margin-bottom: 74px;
	}

	&__list {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: relative;
		max-width: 1100px;
		min-width: 380px;
		margin-left: auto;
		margin-right: auto;
		z-index: 10;
		margin-top: -110px;
		padding: 56px 0 50px;
		border: 1px solid rgba($c-grey, 0.5);
		background-color: $c-white;

		@media (max-width: 1200px) {
			max-width: 100%;
		}

		@include mobile {
			padding-top: 0;
			padding-bottom: 0;
			min-width: 280px;
			margin-top: -100px;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		}
	}

	&__list-item {
		position: relative;
		min-width: 33.33%;
		padding: 20px 40px 10px;
		border-left: 1px dashed rgba($c-grey, 0.7);

		@include tablet {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include mobile {
			padding-top: 50px;
			padding-bottom: 50px;
			border-left: none;
			width: 100%;
		}

		&:first-child {
			border: none;

			&::before {
				display: none;
			}
		}

		&::before {
			@include mobile {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				width: 158px;
				margin-left: -79px;
				border-top: 1px dashed rgba($c-grey, 0.7);
			}
		}
	}

	.h-line {
		max-width: 284px;
		width: 100%;
		margin: 0 auto 18px;
		font-weight: 300;
		font-size: 22px;
		line-height: (30/22);

		@include tabletMaxi {
			padding-bottom: 6px;
			font-size: 20px;
			line-height: (27/20);
		}

		@include mobile {
			padding-bottom: 10px;
			max-width: 200px;
			font-size: 18px;
			line-height: (24/18);
		}
	}
}

.contact-list {
	li {
		font-weight: 300;
		font-size: 22px;
		line-height: (26/22);

		@include tabletMaxi {
			font-size: 18px;
			line-height: (24/18);
		}

		@include tablet {
			font-size: 16px;
			line-height: (22/16);
		}
	}

	a {
		color: $c-accent;
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		@include hover {
			color: rgba($c-accent, 0.8);
		}
	}
}
