.is-hidden {
	display: none !important;
}

.is-invisible {
	opacity: 0 !important;
	pointer-events: none !important;
}

.is-desktop-visible {
	@include tabletMaxi {
		display: none !important;
	}
}
